// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-modules-datocms-post-index-page-page-tsx": () => import("./../src/modules/datocmsPostIndexPage/page.tsx" /* webpackChunkName: "component---src-modules-datocms-post-index-page-page-tsx" */),
  "component---src-modules-datocms-post-page-page-tsx": () => import("./../src/modules/datocmsPostPage/page.tsx" /* webpackChunkName: "component---src-modules-datocms-post-page-page-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */)
}

